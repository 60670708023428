import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 房源管理 - 分页查询
 * @param params
 */
export function queryRoomListByParam(params) {
  return request({
    url: envInfo.bgApp.crmWebPath + '/roomData/queryRoomListByParam',
    method: 'get',
    params,
  });
}

/**
 * 房源管理 - 获取房源项目下拉选项集合
 * @param params
 */
export function getStoreList(params) {
  return request({
    url: envInfo.bgApp.crmWebPath + '/roomData/getStoreList',
    method: 'get',
    params,
  });
}

/**
 * 房源管理 - 修改房源状态
 * @param params
 */
export function editHomeStatus(params) {
  return request({
    url: envInfo.bgApp.crmWebPath + '/roomData/editHomeStatus',
    method: 'post',
    data: params,
  });
}

/**
 * 房源管理 - 根据id查询详情数据
 * @param params
 */
export function getRoomDetail(params) {
  return request({
    url: envInfo.bgApp.crmWebPath + '/roomData/getRoomDetail',
    method: 'get',
    params,
  });
}

/**
 * 房源管理 - 根据项目id获取模板列表
 * @param params
 */
export function queryTemplateByStore(params) {
  return request({
    url: envInfo.bgApp.personnelmanagePath + '/template/queryTemplateByStore',
    method: 'get',
    params,
  });
}

/**
 * 房源管理 - 编辑房源
 * @param params
 */
export function editRoom(params) {
  return request({
    url: envInfo.bgApp.crmWebPath + '/roomData/editRoom',
    method: 'post',
    data: params,
  });
}
