<template>
  <div class="paid-detail-component">
    <div class="paid-name">房源基础信息</div>
    <div class="paid-arrange">
      <div class="paid-arrange-sort">
        <div class="title">所属项目</div>
        <div class="content">{{ tabData.storeName }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">楼栋楼层</div>
        <div class="content">
          {{ tabData.buildingName }}/{{ tabData.floorName }}
        </div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">房号</div>
        <div class="content">{{ tabData.roomName }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">房间用途</div>
        <div class="content">{{ tabData.application || "暂无" }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">建筑面积（㎡）</div>
        <div class="content">{{ tabData.acreage }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">计费面积（㎡）</div>
        <div class="content">{{ tabData.calcAcreage }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">房间信息</div>
        <div class="content">{{ tabData.status }}</div>
      </div>
      <div class="paid-arrange-sort">
        <div class="title">招商状态</div>
        <div class="content">{{ tabData.houseStatusStr }}</div>
      </div>
    </div>
    <div class="paid-name">房源基础信息</div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      label-position="top"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-row :gutter="48" style="width: 55%">
        <el-col :span="12">
          <el-form-item label="房源配置模板项配置" prop="configTemplate">
            <el-select
              :disabled="currentState === 'view'"
              style="width: 80%"
              v-model="ruleForm.configTemplate"
            >
              <el-option
                v-for="(item, index) in configData"
                :value="item.value"
                :label="item.label"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="单价" prop="unitPrice">
            <el-input
              style="width: 80%"
              :disabled="currentState === 'view'"
              maxlength="50"
              v-model="ruleForm.unitPrice"
            >
              <template slot="append">元/m2</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-row :gutter="48" style="width: 55%">
          <el-col :span="12">
            <el-form-item label="递增率" prop="incrementalRate">
              <el-input
                style="width: 80%"
                :disabled="currentState === 'view'"
                maxlength="50"
                v-model="ruleForm.incrementalRate"
              >
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="管理费" prop="managementExpense">
              <el-input
                style="width: 80%"
                :disabled="currentState === 'view'"
                v-model="ruleForm.managementExpense"
              >
                <template slot="append">元/m2</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="48" style="width: 55%">
          <el-col :span="12">
            <el-form-item label="税率" prop="taxRate">
              <el-input
                style="width: 80%"
                :disabled="currentState === 'view'"
                v-model="ruleForm.taxRate"
              >
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="上传图片" prop="photoIdListId">
          <div class="displayImgTip">
            温馨提示：最多上传9张，图片大小不能超过2m，图片格式为jpg，png。
            <br />图片尺寸 335*225像素
          </div>
          <div style="width: 55%" v-if="currentState === 'edit'">
            <el-upload
              :action="uploadPath"
              :limit="9"
              :on-success="handleAvatarSuccess"
              :file-list="fileList"
              :before-upload="beforeAvatarUpload"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
          <div v-else-if="currentState === 'view' && fileList.length > 0">
            <div v-for="(item, index) in fileList" :key="index" class="viewImg">
              <img
                :src="item.url"
                alt=""
                @click.stop="handlePictureCardPreview(item)"
              />
            </div>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
          <div v-else>暂无图片</div>
        </el-form-item>
      </el-row>
      <el-form-item align="right" v-if="currentState !== 'view'">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getRoomDetail,
  queryTemplateByStore,
  editRoom,
} from "@/api/ruge/gsPark/customerService/propertySales";
import { envInfo } from "@/constants/envInfo";
export default {
  name: "detail",
  data() {
    const fileLinkValid = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择模版项配置！"));
        return;
      }
      callback();
    };
    // const validatorPhoto = (rule, value, callback) => {
    //   if (this.currentState === "view") {
    //     callback();
    //     return;
    //   }
    //   if (this.ruleForm.photoIdListId.length === 0) {
    //     callback(new Error("图片为必填"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      tabData: {},
      imageUrl: null,
      fileList: [],
      dialogImageUrl: "",
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      uploadLoading: false,
      currentState: "view",
      dialogVisible: false,
      configData: [],
      ruleForm: {
        configTemplate: "",
        unitPrice: null,
        incrementalRate: null,
        managementExpense: null,
        taxRate: null,
        photoIdListId: [],
      },
      rules: {
        configTemplate: [
          { required: true, validator: fileLinkValid, trigger: "blur" },
        ],
        unitPrice: [
          { required: true, message: "单价为必填项", trigger: "blur" },
        ],
        incrementalRate: [
          { required: true, message: "递增率为必填项", trigger: "blur" },
        ],
        managementExpense: [
          { required: true, message: "管理费为必填项", trigger: "blur" },
        ],
        taxRate: [{ required: true, message: "税率为必填项", trigger: "blur" }],
        // photoIdListId: [{ required: true, validator: validatorPhoto }],
      },
    };
  },
  created() {
    this.getTableList();
    this.currentState = this.$route.query.viewStatus;
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitHandler();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitHandler() {
      const params = {
        roomId: this.tabData.roomId,
        propertyConfigurationId: this.ruleForm.configTemplate,
        incrementRate: this.ruleForm.incrementalRate,
        managementFee: this.ruleForm.managementExpense,
        taxRate: this.ruleForm.taxRate,
        unitPrice: this.ruleForm.unitPrice,
        roomResourceVOS: this.ruleForm.photoIdListId,
      };
      editRoom(params).then((res) => {
        if (res.code === 200) {
          this.$message.success("保存成功");
          this.$router.go(-1);
        }
      });
    },
    getConfigList() {
      this.configData = [];
      queryTemplateByStore({
        storeId: this.tabData.storeId,
      })
        .then((res) => {
          if (res.length > 0) {
            this.configData = res.map((item) => {
              return {
                value: item.id,
                label: item.templateName,
              };
            });
          }
        })
        .catch(() => {
          this.configData = [];
        });
    },
    getTableList() {
      getRoomDetail({
        roomId: this.$route.query.roomId,
      }).then((res) => {
        if (res) {
          this.tabData = res;
          this.ruleForm.unitPrice = res.unitPrice;
          this.ruleForm.incrementalRate = res.incrementRate;
          this.ruleForm.managementExpense = res.managementFee;
          this.ruleForm.taxRate = res.taxRate;
          this.ruleForm.configTemplate = res.propertyConfigurationId;
          this.getConfigList();

          if (res.roomResourceVOS.length > 0) {
            this.fileList = [];
            this.ruleForm.photoIdListId = [];
            this.handleAvatarSuccess(res.roomResourceVOS);
          }
        }
      });
    },
    handleAvatarSuccess(fileList) {
      fileList.map((item) => {
        this.ruleForm.photoIdListId.push({
          fileId: item.fileId,
          fileType: item.contentType ? item.contentType : item.fileType,
          thumbnailId: "",
        });
        this.fileList.push({
          url: `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${item.fileId}`,
          fileId: item.fileId,
        });
      });
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.ruleForm.photoIdListId = this.ruleForm.photoIdListId.filter(
        (item) => item.fileId !== file.fileId
      );
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("封面图片只能是 JPG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("封面图片大小不能超过 2MB!");
      }
      const returnFlag = (isJPG || isPNG) && isLt2M;
      this.uploadLoading = returnFlag;
      return returnFlag;
    },
  },
};
</script>

<style lang="less" scoped>
.paid-detail-component {
  position: relative;
  padding: 32px;
  margin: 32px;
  background: #fff;
  height: 85vh;
  overflow: auto;
  .paid-name {
    width: 96px;
    height: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #2f3941;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .paid-arrange {
    display: flex;
    flex-flow: row wrap;
    width: 874px;
    padding-bottom: 18px;
    .paid-arrange-setWidth {
      width: 874px !important;
    }
    .paid-arrange-sort {
      width: 436px;
      height: 42px;
      margin-bottom: 24px;
      .title {
        height: 18px;
        font-size: 12px;
        font-weight: 300;
        color: #2f3941;
        line-height: 18px;
      }
      .content {
        margin-top: 4px;
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #2f3941;
        line-height: 20px;
      }
    }
    .workOrder {
      position: absolute;
      top: 536px;
      right: 36px;
      width: 116px;
      height: 36px;
      border: 1px solid #1a4cec;
      line-height: 36px;
      text-align: center;
      border-radius: 4px;
      font-size: 14px;
      font-family: Noto Sans SC, Noto Sans SC;
      font-weight: bold;
      color: #1a4cec;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .tips-container {
    font-size: 14px;
    color: #909399;
    & > div {
      height: 24px;
      line-height: 24px;
    }
    .delete-icon {
      position: absolute;
      bottom: 0;
      font-size: 14px;
      color: #ff0000;
      cursor: pointer;
    }
  }
  .avatar-uploader {
    margin-top: 26px;
    border: 1px dotted #ccc;
    height: 178px;
    width: 178px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .viewImg {
    display: inline-block;
    margin-right: 8px;
    img {
      width: 146px;
      height: 146px;
      border: 1px solid #c0ccda;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}
</style>