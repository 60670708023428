var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "paid-detail-component" },
    [
      _c("div", { staticClass: "paid-name" }, [_vm._v("房源基础信息")]),
      _c("div", { staticClass: "paid-arrange" }, [
        _c("div", { staticClass: "paid-arrange-sort" }, [
          _c("div", { staticClass: "title" }, [_vm._v("所属项目")]),
          _c("div", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.tabData.storeName)),
          ]),
        ]),
        _c("div", { staticClass: "paid-arrange-sort" }, [
          _c("div", { staticClass: "title" }, [_vm._v("楼栋楼层")]),
          _c("div", { staticClass: "content" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.tabData.buildingName) +
                "/" +
                _vm._s(_vm.tabData.floorName) +
                "\n      "
            ),
          ]),
        ]),
        _c("div", { staticClass: "paid-arrange-sort" }, [
          _c("div", { staticClass: "title" }, [_vm._v("房号")]),
          _c("div", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.tabData.roomName)),
          ]),
        ]),
        _c("div", { staticClass: "paid-arrange-sort" }, [
          _c("div", { staticClass: "title" }, [_vm._v("房间用途")]),
          _c("div", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.tabData.application || "暂无")),
          ]),
        ]),
        _c("div", { staticClass: "paid-arrange-sort" }, [
          _c("div", { staticClass: "title" }, [_vm._v("建筑面积（㎡）")]),
          _c("div", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.tabData.acreage)),
          ]),
        ]),
        _c("div", { staticClass: "paid-arrange-sort" }, [
          _c("div", { staticClass: "title" }, [_vm._v("计费面积（㎡）")]),
          _c("div", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.tabData.calcAcreage)),
          ]),
        ]),
        _c("div", { staticClass: "paid-arrange-sort" }, [
          _c("div", { staticClass: "title" }, [_vm._v("房间信息")]),
          _c("div", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.tabData.status)),
          ]),
        ]),
        _c("div", { staticClass: "paid-arrange-sort" }, [
          _c("div", { staticClass: "title" }, [_vm._v("招商状态")]),
          _c("div", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.tabData.houseStatusStr)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "paid-name" }, [_vm._v("房源基础信息")]),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            { staticStyle: { width: "55%" }, attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "房源配置模板项配置",
                        prop: "configTemplate",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80%" },
                          attrs: { disabled: _vm.currentState === "view" },
                          model: {
                            value: _vm.ruleForm.configTemplate,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "configTemplate", $$v)
                            },
                            expression: "ruleForm.configTemplate",
                          },
                        },
                        _vm._l(_vm.configData, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单价", prop: "unitPrice" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "80%" },
                          attrs: {
                            disabled: _vm.currentState === "view",
                            maxlength: "50",
                          },
                          model: {
                            value: _vm.ruleForm.unitPrice,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "unitPrice", $$v)
                            },
                            expression: "ruleForm.unitPrice",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("元/m2")])],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-row",
                { staticStyle: { width: "55%" }, attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "递增率", prop: "incrementalRate" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "80%" },
                              attrs: {
                                disabled: _vm.currentState === "view",
                                maxlength: "50",
                              },
                              model: {
                                value: _vm.ruleForm.incrementalRate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "incrementalRate", $$v)
                                },
                                expression: "ruleForm.incrementalRate",
                              },
                            },
                            [_c("template", { slot: "append" }, [_vm._v("%")])],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "管理费", prop: "managementExpense" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "80%" },
                              attrs: { disabled: _vm.currentState === "view" },
                              model: {
                                value: _vm.ruleForm.managementExpense,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "managementExpense",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.managementExpense",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("元/m2"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { width: "55%" }, attrs: { gutter: 48 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "税率", prop: "taxRate" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "80%" },
                              attrs: { disabled: _vm.currentState === "view" },
                              model: {
                                value: _vm.ruleForm.taxRate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "taxRate", $$v)
                                },
                                expression: "ruleForm.taxRate",
                              },
                            },
                            [_c("template", { slot: "append" }, [_vm._v("%")])],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上传图片", prop: "photoIdListId" } },
                [
                  _c("div", { staticClass: "displayImgTip" }, [
                    _vm._v(
                      "\n          温馨提示：最多上传9张，图片大小不能超过2m，图片格式为jpg，png。\n          "
                    ),
                    _c("br"),
                    _vm._v("图片尺寸 335*225像素\n        "),
                  ]),
                  _vm.currentState === "edit"
                    ? _c(
                        "div",
                        { staticStyle: { width: "55%" } },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action: _vm.uploadPath,
                                limit: 9,
                                "on-success": _vm.handleAvatarSuccess,
                                "file-list": _vm.fileList,
                                "before-upload": _vm.beforeAvatarUpload,
                                "list-type": "picture-card",
                                "on-preview": _vm.handlePictureCardPreview,
                                "on-remove": _vm.handleRemove,
                              },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: { visible: _vm.dialogVisible },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogVisible = $event
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100%",
                                  src: _vm.dialogImageUrl,
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm.currentState === "view" && _vm.fileList.length > 0
                    ? _c(
                        "div",
                        [
                          _vm._l(_vm.fileList, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "viewImg" },
                              [
                                _c("img", {
                                  attrs: { src: item.url, alt: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handlePictureCardPreview(item)
                                    },
                                  },
                                }),
                              ]
                            )
                          }),
                          _c(
                            "el-dialog",
                            {
                              attrs: { visible: _vm.dialogVisible },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogVisible = $event
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100%",
                                  src: _vm.dialogImageUrl,
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ],
                        2
                      )
                    : _c("div", [_vm._v("暂无图片")]),
                ]
              ),
            ],
            1
          ),
          _vm.currentState !== "view"
            ? _c(
                "el-form-item",
                { attrs: { align: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }